import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { supabase } from '../../../supabaseClient';
import { useTenant } from '../../../context/TenantContext';

const Funnels = () => {
  const navigate = useNavigate();
  const [funnels, setFunnels] = React.useState([]);
  const { activeTenantId, loading } = useTenant();

  React.useEffect(() => {
    if (!activeTenantId) return;

    const fetchFunnels = async () => {
      try {
        const { data: funnels, error } = await supabase
          .from('funnels')
          .select('*')
          .eq('tenant_id', activeTenantId);

        if (error) throw error;
        
        console.log('Funnels:', funnels);

        setFunnels(funnels);
      } catch (error) {
        console.error('Error fetching funnels:', error);
      }
    };

    fetchFunnels();
  }, [activeTenantId]);

  const handleRowClick = (id) => {
    navigate(`/funnels/${id}/edit`);
  };

  const handleAddNew = () => {
    navigate('/reports/funnels/new');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ paddingTop: 0, paddingLeft: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '64px', marginBottom: 2 }}>
        <Typography variant="h4" component="h1">
          Funnels
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddNew}
          size="small"
          sx={{ marginRight: 2 }}
        >
          New Funnel
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funnels.map((funnel) => (
              <TableRow
                key={funnel.id}
                hover
                onClick={() => handleRowClick(funnel.id)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{funnel.name}</TableCell>
                <TableCell>{funnel.data?.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Funnels;
