const routes = {
    '/': {
        items: [{ text: 'Home' }]
    },
    '/dashboards': {
        items: [{ text: 'Dashboards' }]
    },
    '/leadgen': {
        items: [{ text: 'Lead Generation' }]
    },
    '/enrichment': {
        items: [{ text: 'Data Enrichment' }]
    },
    '/form-shortening': {
        items: [{ text: 'Form Shortening' }]
    },
    '/custom-workflows': {
        items: [{ text: 'Custom Workflows' }]
    },
    '/workspace': {
        items: [
            { text: 'Workspace Settings' }
        ]
    },
    '/user/settings': {
        items: [
            { text: 'User Settings' }
        ]
    },
    '/user/reset-password': {
        items: [
            { text: 'Reset Password' }
        ]
    },
    '/leadgen/configure': {
        items: [
            { text: 'Lead Generation', href: '/leadgen' },
            { text: 'Configuration' }
        ]
    },
    '/leadgen/campaigns': {
        items: [
            { text: 'Lead Generation', href: '/leadgen' },
            { text: 'Campaigns' }
        ]
    },
    '/reports': {
        items: [
            { text: 'Reports' }
        ]
    }
    ,
    '/reports/lifecycle-cohort': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Lifecycle Cohort' }

        ]
    },
    '/reports/conversions': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Conversions' }

        ]
    },
    '/reports/funnels': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Funnels' }

        ]
    },
    '/reports/funnels/new': {
        items: [
            { text: 'Reports', href: '/reports' },
            { text: 'Funnels', href: '/reports/funnels' },
            { text: 'New Funnel' }

        ]
    }

};

// Helper function to handle dynamic routes
export const getBreadcrumbItems = (pathname) => {
    // First check for exact matches
    if (routes[pathname]) {
        return routes[pathname].items;
    }

    // Handle dynamic routes
    if (pathname.match(/\/custom-workflows\/[^/]+/)) {
        return [
            { text: 'Custom Workflows', href: '/custom-workflows' },
            { text: 'Workflow Details' }
        ];
    }

    if (pathname.match(/\/funnels\/.*\/edit/)) {
        return [
            { text: 'Reports', href: '/reports' },
            { text: 'Funnels', href: '/reports/funnels' },
            { text: 'Edit Funnel' }
        ];
    }

    if (pathname.match(/\/funnels\/new/)) {
        return [
            { text: 'Reports', href: '/reports' },
            { text: 'Reports', href: '/reports/funnels' },
            { text: 'New Funnel' }
        ];
    }

    // Default fallback
    return [{ text: 'Home' }];
};

export default routes; 